import React from 'react';
import { Message } from '../types/types';
import Avatar from './Avatar';  // Assuming Avatar component is defined

import './MessageList.scss';
import {log} from "node:util";



  interface MessageListProps {
    messages: Message[];

  }

  const MessageList: React.FC<MessageListProps> = ({ messages }) => {
    return (
<div className='message-list'>
          {
          messages.map((msg, index) => {
            console.log(JSON.stringify(msg))
            return(
              <div key={index} className={`message ${msg.sender}`}>
                {/* <div>{msg.sender === 'bot' && <Avatar />} </div> */}
                <div>{ <Avatar sender={msg.sender}/>} </div>
                <div className={`message ${msg.sender} message-content`}>{msg.text}</div>
              </div>
          )})}


      </div>
    );
  };

  export default MessageList;



