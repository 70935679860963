import Navbar from "react-bootstrap/Navbar";
import {ChevronDown} from "react-bootstrap-icons";
import {useDispatch, useSelector} from "react-redux";
import {minimizeChat} from "../store/actions/botState";

import "./Navbar.scss";

function NavbarContainer() {
  const config:any = useSelector((state: any) => state.chat.config);

  const dispatch = useDispatch();
  
  return (
    <Navbar expand="lg" variant="dark" className="bot-navbar">
      <Navbar.Brand className="bot-navbar-brand">{config.chatbot.name}</Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text className="close-button">
          <a
            href="#"
            onClick={() => {
              dispatch(minimizeChat());
            }}
          >
            <ChevronDown size={25} />
          </a>
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavbarContainer;
