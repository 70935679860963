import {BotAction} from "../../types/types";
const botFirstMessages = [{"text": "Hello! How can I help you today?", "sender": "bot"}]
const initialMessages = JSON.parse(localStorage.getItem('messages') || JSON.stringify(botFirstMessages));

const initialState = {
  // Other initial state properties...
  messages: initialMessages,
};

const conversationReducer = (state = initialState, action: BotAction) => {
  switch (action.type) {
    case "ADD_MESSAGE": {
      // Save messages to localStorage
      const messages = JSON.parse(localStorage.getItem("messages") || JSON.stringify(botFirstMessages));
      localStorage.setItem("messages", JSON.stringify([...messages, action.payload]));

      return {
        ...state,
        messages: [...state.messages, action.payload],
      };
    }
    default:
      return state;
  }
};

export default conversationReducer;
