import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {Send} from "react-bootstrap-icons";
import useBackendApi from "../hooks/useBackendApi";
import {connect, useSelector} from "react-redux";
import {BotState} from "../types/types";
import React, {useState} from "react";

import "./MessageInput.scss";

interface MessageInputProps {
  // Add any necessary props
}

const MessageInput: React.FC<MessageInputProps> = (config) => {
  const [inputValue, setInputValue] = useState("");
  const {sendMessage}: any = useBackendApi();
  const configFromState = useSelector(
    (state: any) => state.chat.config.chatbot
  );

  const handleSubmit = (e: any) => {
    e.preventDefault(); // Prevent default form submission behavior
    if (!inputValue) return;
    sendMessage(inputValue);
    setInputValue("");
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value); // Update the input value state
  };
  return (
    <Form className="message-input" onSubmit={handleSubmit}>
      <InputGroup className="mb-3 input-field" color="gray">
        <Form.Control
          type="text"
          aria-label="input"
          placeholder={configFromState.inputFieldPlaceholder}
          value={inputValue}
          onChange={handleInputChange}
        />
        <Button className="submit-button" variant="light" type="submit">
          <Send className="ml-3 send" size={22} />
        </Button>
      </InputGroup>
    </Form>
  );
};

const mapStateToProps = (state: BotState) => ({
  config: state.chat.config.chatbot,
});

export default connect(mapStateToProps)(MessageInput);
