import {useDispatch} from "react-redux";
import {addMessage} from "../store/actions/messagesAction";
import {
  sendMessageToBackend,
  connectToBackend,
} from "../api/backendConnector";
import {responseType} from "../types/types"; // Assuming your class is in a separate file

const useBackendApi = () => {
  const dispatch = useDispatch();

  const connect = async () => {
    await connectToBackend();
  };

  const sendMessage = async (text: string) => {
    dispatch(addMessage({text: text, sender: "user"}));
    await sendMessageToBackend(text).then((receivedMessages) =>{
      receivedMessages?.forEach((element)=>{
        const next = element as responseType
        // const messageType:string = element.message
        switch (next.message){
          case "text":
            dispatch(addMessage({text: next.text.text[0], sender: "bot"}));
            return
          case "payload":{
            const richContent = next.payload.fields.richContent.listValue.values[0].listValue.values[0].structValue.fields
            if(richContent.type.stringValue === "chips"){
              const chips = richContent.options.listValue.values
              chips.forEach((chip:any)=>{
                dispatch(addMessage({text: chip.structValue.fields.text.stringValue, sender: "bot"}));

              })
            }
          }
        }
      })
    }) ;
  };

  // const onReciveMessage = () => {
  //
  //   receiveMessageFromBackend((data) => {
  //     if (
  //       data &&
  //       data.messages &&
  //       data.messages[0] &&
  //       data.messages[0].direction === "Outbound"
  //       ) {
  //         const receivedMessage = data.messages[0].text;
  //       dispatch(addMessage({text: receivedMessage, sender: "bot"}));
  //     }
  //   });
  // };

  return {sendMessage, connect};
};

export default useBackendApi;
