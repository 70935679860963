import Chatbot from "./components/Chatbot";
import React, {useEffect} from "react";
import useBackendApi from "./hooks/useBackendApi";
import {useDispatch, useSelector} from "react-redux";
import {addLocationConfig, initConfig} from "./store/actions/botState";
import updateStyleFromConfig from "./hooks/updateStyleFromConfig"

import "./App.scss";

const App: React.FC = () => {
  const {connect}: any = useBackendApi();
  const dispatch = useDispatch();
  let initialized = false;
  const config:any = useSelector((state: any) => state.chat.config);
  console.log(config.theme);
  async function initialize() {
    if (!initialized) {
      connect();
      dispatch(initConfig());
      const currentURL = window.location.href;
      dispatch(addLocationConfig(currentURL));
    }
}

useEffect(() => {
    initialize();
    return () => {
        initialized = true;
    };
}, []);

const updatedStyle = updateStyleFromConfig(config) as React.CSSProperties;

  return (
    <main>
      <div style={updatedStyle}>
        <Chatbot />
      </div>
    </main>
  );
};

export default App;
