import {responseType, data} from "../types/types";

const cloudFunctionUrl = process.env.REACT_APP_MIDDLEWARE_URL as string;
const frontendUrl = process.env.REACT_APP_FRONTEND_URL as string
const sessionId = localStorage.getItem('chatId') || Math.random().toString(36).substring(2, 15);
export const connectToBackend = async (): Promise<void> => {
    // Fetch the Cloud Function URL from an environment variable or configuration

    // Prepare an initial session ID (optional)

    const response = await fetch(cloudFunctionUrl, {
        mode: 'cors',
        method: 'POST',
        headers: {'Content-Type': 'text/plain', 'Access-Control-Allow-Origin': frontendUrl},
        body: JSON.stringify({sessionId: sessionId, message: ""}) // Include session ID for context continuity
    })
    try {
        const data = await response.json();
        console.log(data)
        return data
        // Handle the parsed JSON data
    } catch (error) {
        console.error('Error parsing JSON:', error);
        // Handle the error appropriately
    }

    // if (response.ok) {
    //     // Handle successful connection (e.g., store session ID)
    //     localStorage.setItem('chatId', sessionId); // Persist session ID
    //     console.log('Connected to backend');
    // } else {
    //     console.error('Error connecting to backend:', await response.text());
    // }

}


// export const receiveMessageFromBackend = (
//     callback: (message: any) => void
// ): void => {
//     Genesys("subscribe", "MessagingService.ready", () => {
//         Genesys("subscribe", "MessagingService.messagesReceived", ({data}: any) => {
//             callback(data);
//         });
//     });
// };

export const sendMessageToBackend = async (text: string): Promise<Array<object>> => {
    let answers:Array<responseType> = [];
  if (!cloudFunctionUrl) { // Check if connection is established
    console.error('Backend connection not established. Call backendConnect() first.');
    return [{}];
  }
      await fetch(cloudFunctionUrl, {
          mode: 'cors',
          method: 'POST',
          headers: {'Content-Type': 'text/plain', 'Access-Control-Allow-Origin': frontendUrl},
          body: JSON.stringify({sessionId: sessionId, message: text}) // Include session ID for context continuity
      }).then(async (response) => {
          if (response.ok) {
              const data = await response.json();
              // Handle received response from backend (e.g., update chat UI with bot's response)
              console.log('Message sent:', data.responseMessages[0].text.text[0]); // Example: log bot's response
              answers= data.responseMessages
          } else {
              console.error('Error sending message:', await response.text());
          }
      }).catch(error => {
          console.error('Error sending message:', error);

      })
    return answers
}

