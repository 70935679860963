const config: any = {
    // Common configurations
    theme: {
      primary: '#0071B2',
      secondary: '#6c757d',
      background: '#f8f9fa',
      text: '#343a40',
    },
    fonts: {
      primary: 'Arial, sans-serif',
      size: '14px',
    },
    chatbot: {
      name: "Chatbot",
      welcomeMessage: 'Welcome to our chatbot! How can I assist you today?',
      inputFieldPlaceholder: 'Default Placeholder (Wird Überschreiben)',
      avatar: '/images/avatar.png',
    },
    api: {
      genesys: {
        baseUrl: 'https://genesys.example.com/api',
        apiKey: 'YOUR_API_KEY',
      },
    },
    // URL-specific configurations
    routes: {
      '#': {
        theme: {
          primary: '#479b3d',
          secondary: '#6c757d',
          // background: '#6bb03a',
          background: '#f8f9fa',
          text: '#343a40',
        },
        chatbot: {
          name: "Wowi Chatbot",
          welcomeMessage: 'Hallo, ich bin Wowi, Ihr Chatbot. Wie kann ich Ihnen helfen?',
          inputFieldPlaceholder: 'Frage eingeben',
          avatar: '/images/avatar.png',
        },
      },
      '/home': {
        colors: {
          primary: '#ff0000', // Red primary color for the homepage
        },
      },
      '/about': {
        fonts: {
          size: '16px', // Larger font size for the about page
        },
      },
      // Add more URL configurations as needed
    },
  };

  export default config;
