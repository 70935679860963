import React from 'react';
import {Robot} from "react-bootstrap-icons";


import './Avatar.scss'

const Avatar = ({sender}: any) => {
    if (sender === 'bot') {
        return <Robot size={10} color={"green"} className='avatar'/>
    } else {
        return (
            <img src={require("../assets/user.jpg")} alt="Bot Avatar" className="avatar"/>)
    }
}

export default Avatar;
