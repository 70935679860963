// src/components/Chatbot.tsx

import {connect} from "react-redux";
import NavbarContainer from "./Navbar";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import React from "react";
import {BotState, Message} from "../types/types";
import {maximizeChat} from "../store/actions/botState";
import MinimizedChat from "./MinimizedChat";

import "./Chatbot.scss";
import "./MessageInput.scss";

interface ChatbotProps {
  // Add any necessary props
  maximizeChat: () => void;
  minimized: boolean;
  messages: Message[];
}

const Chatbot: React.FC<ChatbotProps> = ({
  minimized,
  maximizeChat,
  messages,
}) => {


  return (
    <div>
      {minimized ? (
        <MinimizedChat />
      ) : (
        <div className="chatbot-wrapper">
          <header className="chatbot-header">
            <NavbarContainer />
          </header>
          <MessageList messages={messages} />
          <MessageInput />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state: BotState) => ({
  minimized: state.chat.minimized,
  messages: state.messages.messages,
});

const mapDispatchToProps = {
  maximizeChat,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chatbot);
