// actions/chatActions.js
import config from "../config";

const getLocationConfig = (url: string) => {
  const location = url.split("/")[3];
  if (!config.routes[location]) return config;
  console.log(config.routes[location]);
  return config.routes[location];
};

export const minimizeChat = () => ({
  type: "MINIMIZE_CHAT",
});

export const maximizeChat = () => ({
  type: "MAXIMIZE_CHAT",
});

export const addLocationConfig = (currentURL: string) => {
  return ({
  type: "ADD_CONFIG",
  payload: getLocationConfig(currentURL),
})};

export const initConfig = () =>{
   return ({
  type: "INIT_CONFIG",
})};
