import { BotAction } from "../../types/types";
import config from "../config";

// reducers/chatReducer.js
const initialState = {
    minimized: true,
    config: config,
  };
  
  const botStateReducer = (state = initialState, action:BotAction) => {
    
    switch (action.type) {
      case 'MINIMIZE_CHAT':
        return { ...state, minimized: true };
      case 'MAXIMIZE_CHAT':
        return { ...state, minimized: false };
      case 'INIT_CONFIG':
        return { ...state, config: config  };
      case 'ADD_CONFIG':
        return { ...state, ...{config:action.payload}  };
      default:
        return state;
    }
  };
  export default botStateReducer;
  