// Import configureStore from @reduxjs/toolkit
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/combineReducer';

// Use configureStore to create the Redux store
const store = configureStore({
  reducer: rootReducer,
  // Add other configuration options if needed
});

export default store;
