import {useDispatch} from "react-redux";
import {maximizeChat} from "../store/actions/botState";

import './MinimizedChat.scss'

function MinimizedChat() {
  const dispatch = useDispatch();
  return (
    // <div className="minimized-chat">
    // <button className="minimized-chat" onClick={() => dispatch(maximizeChat())}><ChatTextFill size={45}/></button>
    <button className="minimized-chat" onClick={() => dispatch(maximizeChat())}>Maximize Chat</button>
    // </div>
  );
}

export default MinimizedChat;
