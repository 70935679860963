// reducers/index.js
import { combineReducers } from 'redux';
import botStateReducer from './botStateReducer';
import conversationReducer from './conversationReducer';

const rootReducer = combineReducers({
  chat: botStateReducer,
  messages: conversationReducer,
  // Add other reducers as needed
});

export default rootReducer;
